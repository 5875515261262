import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faDesktop,
  faMobileAlt,
  faFolderOpen,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons' // fas

// import { } from '@fortawesome/free-regular-svg-icons' // far
// import {} from '@fortawesome/free-brands-svg-icons' // fab

library.add(
  faDesktop,
  faMobileAlt,
  faFolderOpen,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
