/* eslint-disable no-param-reassign */
import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    supportWebp: false,
    windowWidth: 0,
    shallShowOverlay: false,
    userData: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '',
    cart: JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : '',
    titleTemplate: 'GameHost 抗攻擊主機|無限防論壇|抗DDOS空間|抗DDOS論壇|自動贊助',
    notFoundImg: 'https://cdn.web-hosts.net/web/notfound.png',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_USERDATA_STATE(state, val) {
      state.userData = val
    },
    CHECK_SUPPORT_WEBP(state, val) {
      state.supportWebp = val
    },
  },
  actions: {},
}
