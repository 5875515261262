import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'
import VueCountryCode from 'vue-country-code'
// import VueSocketIO from 'vue-socket.io'
// import socketClient from 'socket.io-client'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/font-awesome-icon'
import '@/libs/sweet-alerts'
import '@/libs/socket-client'
import '@/libs/v-viwer'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueMeta)
Vue.use(VueCountryCode)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueDOMPurifyHTML)
Vue.use(VueSocialSharing)

// const options = { path: '/dceaa455de4b621b3e20f9bcf7fbbe66/' }

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: socketClient('', options),
//   vuex: {
//     store,
//   },
// }))

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
