import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import admin from './routes/admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...routes,
    ...admin,
    {
      path: '*',
      name: 'error-404',
      redirect: false,
      component: () => import('@/views/template/error/404.vue'),
      meta: {
        layout: 'full',
        // pageTitle: '後台首頁',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // window.routerChange()
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
