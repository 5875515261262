export default [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/template/home/Template.vue'),
    meta: {
      layout: 'full',
      // pageTitle: '後台首頁',
    },
  },
  {
    path: '/home.html',
    name: 'home',
    component: () => import('@/views/template/home/Template.vue'),
    meta: {
      layout: 'full',
      // pageTitle: '後台首頁',
    },
  },
  {
    path: '/category.html',
    name: 'category',
    component: () => import('@/views/template/category/Category.vue'),
    meta: {
      layout: 'full',
      // pageTitle: '後台首頁',
    },
  },
  {
    path: '/category/:category_name-c.:category_id.html',
    name: 'class',
    component: () => import('@/views/template/forum/Forum.vue'),
    meta: {
      layout: 'full',
      // pageTitle: '後台首頁',
    },
  },
  {
    path: '/article/:article_name-a.:article_id.html',
    name: 'article',
    component: () => import('@/views/template/article/Article.vue'),
    meta: {
      layout: 'full',
      // pageTitle: '後台首頁',
    },
  },
  // {
  //   path: '/error404.html',
  //   name: 'error-404',
  //   component: () => import('@/views/template/error/404.vue'),
  //   meta: {
  //     layout: 'full',
  //     // pageTitle: '後台首頁',
  //   },
  // },
]
