/* eslint-disable no-param-reassign */
// import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    serverName: '',
    classData: null,
    baseUrl: '',
  },
  getters: {
  },
  mutations: {
    UPDATE_SERVER_NAME(state, val) {
      state.serverName = val
    },
    UPDATE_CLASS_DATA(state, val) {
      state.classData = val
    },
    UPDATE_BASE_URL(state, val) {
      state.baseUrl = val
    },
  },
  actions: {
    loadTawkToScript(ctx, url) {
      if (!url) return
      const tawkScript = document.createElement('script')
      tawkScript.type = 'text/javascript'
      tawkScript.async = true
      tawkScript.src = url
      tawkScript.charset = 'UTF-8'
      tawkScript.setAttribute('crossorigin', '*')

      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tawkScript, firstScriptTag)

      const tawkAPI = document.createElement('script')
      tawkAPI.type = 'text/javascript'
      tawkAPI.text = `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      `
      firstScriptTag.parentNode.insertBefore(tawkAPI, tawkScript)
    },
  },
}
